<template>
    <div>
        <Header>
            <template v-slot:header-title>
                Marketplace
            </template>
            <template v-slot:header-strapline>
                Everything we offer within our marketplace is done with BYOL (bring your own license), we are not resellers or distributors of these products.
                <br><br>
                Our role is to simplify the transition to a hybrid multi-cloud environment by building and maintaining the templates within our library.
            </template>
        </Header>
        <section class="marketplace-grid-section">
            <div class="marketplace-grid-controls"></div>
            <div class="marketplace-grid-filter-controls">
                <div class="filter-control provider-type" @click="FilterPartners('all')" :class="{active: showAll}">
                     All
                 </div>
                <div class="filter-control provider-type" @click="FilterPartners('cloud')" :class="{active: showClouds}">
                     Clouds
                 </div>
                <div class="filter-control provider-type" @click="FilterPartners('operating-system')" :class="{active: showOperatingSystems}">
                     Operating Systems
                 </div>
                <div class="filter-control provider-type" @click="FilterPartners('soft-appliance')" :class="{active: showSoftAppliances}">
                     Soft Appliances
                 </div>
                <div class="filter-control provider-type" @click="FilterPartners('application')" :class="{active: showApplications}">
                     Applications
                 </div>
            </div>
            <MarketplaceGrid></MarketplaceGrid>
        </section>
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import MarketplaceGrid from "../components/marketplace/MarketplaceGrid.vue";

export default {
    components: { Header, MarketplaceGrid },
    metaInfo() {
        return {
            title: 'Marketplace | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: 'Everything we offer within our marketplace is done with BYOL (bring your own license), we are not resellers or distributors of these products. Our role is to simplify the transition to a hybrid multi-cloud environment by building and maintaining the templates within our library.'
                },
                {
                    property: 'og:title',
                    content: 'Marketplace | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: 'Everything we offer within our marketplace is done with BYOL (bring your own license), we are not resellers or distributors of these products. Our role is to simplify the transition to a hybrid multi-cloud environment by building and maintaining the templates within our library.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/platform/marketplace'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/platform/marketplace'
                }
            ]
        }
    },
    data: () => ({
        showAll: true, showClouds: false, showOperatingSystems: false, showSoftAppliances: false, showApplications: false
    }),
    methods: {
        FilterPartners(type) {
            switch(type) {
                case "all":
                    this.FilterAllProviders();
                    break;
                case "cloud":
                    this.FilterCloudProviders();
                    break;
                case "operating-system":
                    this.FilterOperatingSystemProviders();
                    break;
                case "soft-appliance":
                    this.FilterSoftApplianceProviders();
                    break;
                case "application":
                    this.FilterApplicationProviders();
                    break;
                default:
                    break;
            }
        },
        FilterAllProviders() {
            this.$root.$emit('filter-provider', 'all');
            this.showAll = true;
            this.showClouds = false;
            this.showOperatingSystems = false;
            this.showSoftAppliances = false;
            this.showApplications = false;
        },
        FilterCloudProviders() {
            this.$root.$emit('filter-provider', 'cloud');
            this.showClouds = true;
            this.showAll = false;
            this.showOperatingSystems = false;
            this.showSoftAppliances = false;
            this.showApplications = false;
        },
        FilterOperatingSystemProviders() {
            this.$root.$emit('filter-provider', 'operating-system');
            this.showOperatingSystems = true;
            this.showAll = false;
            this.showClouds = false;
            this.showSoftAppliances = false;
            this.showApplications = false;
        },
        FilterSoftApplianceProviders() {
            this.$root.$emit('filter-provider', 'soft-appliance');
            this.showSoftAppliances = true;
            this.showAll = false;
            this.showClouds = false;
            this.showOperatingSystems = false;
            this.showApplications = false;
        },
        FilterApplicationProviders() {
            this.$root.$emit('filter-provider', 'application');
            this.showApplications = true;
            this.showAll = false;
            this.showClouds = false;
            this.showOperatingSystems = false;
            this.showSoftAppliances = false;
        }
    }
}
</script>

<style scoped>
.marketplace-grid-section {
    background-color: #f4f7f9;
    padding-bottom: 160px;
}
.marketplace-grid-section .marketplace-grid-controls {
    position: relative;
    background-color: #fff;
    height: 70px;
    margin-bottom: 5px;
}
.marketplace-grid-section .marketplace-grid-filter-controls {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px 0;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    overflow-x: auto;
}
.marketplace-grid-section .marketplace-grid-filter-controls .filter-control {
    position: relative;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.15s ease,
                color 0.15s ease;
    user-select: none;
    border-radius: 4px;
    white-space: nowrap;
}
.marketplace-grid-section .marketplace-grid-filter-controls .filter-control:hover {
    color: var(--brand-accent-blue);
}
.marketplace-grid-section .marketplace-grid-filter-controls .filter-control:not(:first-child) {
    margin-left: 10px;
}
.marketplace-grid-section .marketplace-grid-filter-controls .filter-control.active {
    color: var(--brand-accent-blue);
}
.marketplace-grid-section .marketplace-grid-filter-controls .filter-control::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--brand-accent-blue);
    border-radius: 50px;
    bottom: 8px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.2s ease;
}
.marketplace-grid-section .marketplace-grid-filter-controls .filter-control:hover::after {
    width: 40%;
}
.marketplace-grid-section .marketplace-grid-filter-controls .filter-control.active::after {
    width: 40%;
}
@media only screen and (max-width: 600px) {
    .marketplace-grid-section .marketplace-grid-filter-controls {
        padding: 5px 20px;
    }
}
@media only screen and (min-width: 600px) {
    .marketplace-grid-section .marketplace-grid-filter-controls {
        justify-content: center;
    }
}
</style>