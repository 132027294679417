<template>
    <div>
        <div class="search-bar-wrapper">
            <div class="relative search-bar-container">
                <input type="text" class="provider-search" placeholder="Search for providers" v-model="search">
                <div class="reset-text-button" @click="ClearSearchBar">
                    <i class="bi bi-x-circle-fill"></i>
                    <span id="tooltip">Clear Search</span>
                </div>
            </div>
        </div>
        <!-- Show all of the providers when the search bar is empty -->
        <div class="marketplace-grid-container" v-if="search == ''">
            <div class="provider-content" v-for="provider of providers" :key="provider">
                <div class="provider">
                    <div class="provider-logo">
                        <img :src="require(`../../assets/marketplace/${provider.src}`)" :alt="provider.name">
                    </div>
                    <!-- <div class="provider-details">
                        {{provider.description}}
                    </div> -->
                </div>
                <div class="provider-name">
                    {{provider.name}}
                </div>
            </div>
        </div>
        <!-- Show all of the providers when there is a search term -->
        <div class="marketplace-grid-container" v-if="search != ''">
            <div class="provider-content" v-for="provider of SearchProviders" :key="provider">
                <div class="provider">
                    <div class="provider-logo">
                        <img :src="require(`../../assets/marketplace/${provider.src}`)" :alt="provider.name">
                    </div>
                    <!-- <div class="provider-details">
                        {{provider.description}}
                    </div> -->
                </div>
                <div class="provider-name">
                    {{provider.name}}
                </div>
            </div>
        </div>
        <!-- No Results Found -->
        <div class="no-results-wrapper" v-show="noResults">
            <div class="no-results-icon">
                <i class="bi bi-search"></i>
            </div>
            <div class="no-results-heading">
                No results found.
            </div>
            <div class="no-results-text">
                We can't find any item matching your search.
            </div>
        </div>
    </div>
</template>

<script>
const providerData = [
    {
        name: "A10",
        src: "a10.webp",
        type: "soft-appliance"
    },
    {
        name: "Adobe Creative Cloud",
        src: "adobecc.webp",
        type: "application"
    },
    {
        name: "Apache",
        src: "apache.webp",
        type: "soft-appliance"
    },
    {
        name: "Arcserve UDP",
        src: "arcserve-udp.webp",
        type: "soft-appliance"
    },
    {
        name: "Arcserve Zetta",
        src: "arcserve-zetta.webp",
        type: "soft-appliance"
    },
    {
        name: "Avid InterPlay",
        src: "avid-ip.webp",
        type: "application"
    },
    {
        name: "Avid Media Composer",
        src: "avid-mc.webp",
        type: "application"
    },
    {
        name: "AWS",
        src: "aws.webp",
        type: "cloud"
    },
    {
        name: "Azure",
        src: "azure.webp",
        type: "cloud"
    },
    {
        name: "CentOS",
        src: "centos.webp",
        type: "operating-system"
    },
    {
        name: "Checkpoint",
        src: "checkpoint.webp",
        type: "soft-appliance"
    },
    {
        name: "F5 Networks",
        src: "f5.webp",
        type: "soft-appliance"
    },
    {
        name: "Fortinet",
        src: "fortinet.webp",
        type: "soft-appliance"
    },
    {
        name: "Google Cloud Platform",
        src: "gcp.webp",
        type: "cloud"
    },
    {
        name: "Gigamon",
        src: "gigamon.webp",
        type: "soft-appliance"
    },
    {
        name: "Infoblox",
        src: "infoblox.webp",
        type: "soft-appliance"
    },
    {
        name: "Mac OS",
        src: "macos.webp",
        type: "operating-system"
    },
    {
        name: "MySQL",
        src: "mysql.webp",
        type: "soft-appliance"
    },
    {
        name: "NGINX",
        src: "nginx.webp",
        type: "soft-appliance"
    },
    {
        name: "Palo Alto Networks",
        src: "palo-alto-networks.webp",
        type: "soft-appliance"
    },
    {
        name: "Davinci Resolve",
        src: "resolve.webp",
        type: "application"
    },
    {
        name: "Silver Peak",
        src: "silver-peak.webp",
        type: "soft-appliance"
    },
    {
        name: "Sonicwall",
        src: "sonicwall.webp",
        type: "soft-appliance"
    },
    {
        name: "Tomcat",
        src: "tomcat.webp",
        type: "soft-appliance"
    },
    {
        name: "Ubuntu",
        src: "ubuntu.webp",
        type: "operating-system"
    },
    {
        name: "Veeam",
        src: "veeam.webp",
        type: "soft-appliance"
    },
    {
        name: "Windows 10",
        src: "windows10.webp",
        type: "operating-system"
    },
    {
        name: "Windows 2012",
        src: "windows2012r2.webp",
        type: "operating-system"
    },
    {
        name: "Windows 2016",
        src: "windows2016.webp",
        type: "operating-system"
    },
    {
        name: "Windows 2019",
        src: "windows2019.webp",
        type: "operating-system"
    }
]
export default {
    data: () => ({
        providerData,
        providers: [],
        search: "",
        filterProviderType: "",
        noResults: false
    }),
    created () {
        this.providers = this.providerData.sort(this.Compare);
    },
    watch: {
        providers () {
            this.CheckForFilteredProviderResults();
        },
        SearchProviders () {
            this.CheckForFilteredProviderResults();
        }
    },
    computed: {
        SearchProviders: function() {
            if (this.search != "") {
                return this.providerData.filter((provider) => {
                    return provider.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                });
            } else {
                return this.providerData;
            }
        }
    },
    methods: {
        Compare(a, b) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        },
        ProviderFilter() {
            let tempData = JSON.parse(JSON.stringify(this.providerData));

            if(this.filterProviderType != "")
                tempData = tempData.filter((provider) => provider.type == this.filterProviderType);

            this.providers = tempData;
        },
        ClearSearchBar() {
            this.search = "";
        },
        CheckForFilteredProviderResults() {
            if (this.SearchProviders.length === 0 && this.providers.length === 0) {
                this.noResults = true;
            }
            if (this.providers.length > 0 && this.SearchProviders.length === 0) {
                this.noResults = true;
            }
            if (this.SearchProviders.length > 0 && this.providers.length === 0) {
                this.noResults = false;
                if (this.search === "") {
                    this.noResults = true;
                }
            }
            if (this.SearchProviders.length > 0 && this.providers.length > 0) {
                this.noResults = false;
            }
        },
    },
    mounted: function () {
        this.$root.$on('filter-provider', (type) => {
            this.filterProviderType = type;

            if (type == "all") {
                this.filterProviderType = "";
                this.providers = this.providerData;
            }
            else {
                this.ProviderFilter();
            }
        })
    }
}
</script>

<style scoped>
.search-bar-wrapper {
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.search-bar-wrapper .search-bar-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
}
.search-bar-wrapper .search-bar-container .provider-search {
    position: relative;
    border: none;
    border-radius: 6px;
    padding: 25px 70px 25px 25px;
    width: 100%;
    max-width: 800px;
    z-index: 2;
    box-shadow: 0 3px 52px 0 rgba(0,0,0,0.16);
    font-size: 18px;
    color: var(--text-dark-terniary);
    font-weight: 300;
    font-family: inherit;
    transition: box-shadow 0.4s ease;
}
.search-bar-wrapper .search-bar-container .provider-search:focus {
    outline: none !important;
    box-shadow: 0 3px 52px 0 #00bcc536 !important;
}
.search-bar-wrapper .search-bar-container .reset-text-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0 25px;
    right: 14px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    color: var(--text-dark-secondary);
    font-size: 20px;
    z-index: 2;
}
.search-bar-wrapper .search-bar-container #tooltip {
    position: absolute;
    top: -20px;
    width: 100px;
    font-size: 14px;
    padding: 10px;
    background-color: rgba(0,0,0,0.8);
    border-radius: 3px;
    color: white;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: opacity 0.2s ease,
                transform 0.2s ease;
}
.search-bar-wrapper .search-bar-container .reset-text-button:hover #tooltip {
    opacity: 1;
    transform: translateY(0px);
}
.marketplace-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    row-gap: 40px;
    column-gap: 20px;
    justify-content: center;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 15px;
}
.marketplace-grid-container .provider-content:hover .provider {
    box-shadow: 0 3px 52px 0 rgba(0,0,0,0.08);
}
.marketplace-grid-container .provider-content:hover .provider .provider-logo img {
    width: 80%;
}
.marketplace-grid-container .provider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    height: 200px;
    border-radius: 6px;
    transition: box-shadow 0.3s ease;
}
.marketplace-grid-container .provider .provider-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.marketplace-grid-container .provider .provider-logo img {
    width: 70%;
    transition: width 0.2s ease;
}
.marketplace-grid-container .provider-content .provider-name {
    margin-top: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--text-dark-terniary);
    font-size: 14px;
}
.no-results-wrapper {
    max-width: var(--max-width);
    margin: 100px auto 0 auto;
    display: grid;
    grid-gap: 20px;
    justify-content: center;
    text-align: center;
}
.no-results-wrapper .no-results-icon i {
    font-size: 75px;
    color: var(--text-dark-secondary);
}
.no-results-wrapper .no-results-heading {
    font-size: 20px;
    font-weight: 500;
}
.no-results-wrapper .no-results-text {
    font-size: 20px;
    color: var(--text-dark-secondary);
    max-width: 300px;
}
@media only screen and (max-width: 768px) {
    .marketplace-grid-container {
        grid-template-columns: repeat(auto-fill, 150px);
    }
    .marketplace-grid-container .provider {
        height: 150px;
    }
}
</style>