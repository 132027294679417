<template>
    <div class="header" :class="{ 'small': !hasStraplineSlot }">
        <div class="container">
            <div class="header-content">
                <h2 class="header-title" v-if="hasTitleSlot">
                    <slot name="header-title"></slot>
                </h2>
                <h4 class="header-strapline" :class="{ 'overlapping': overlappingContent }" v-if="hasStraplineSlot">
                    <slot name="header-strapline"></slot>
                </h4>
            </div>
        </div>
        <div class="header-glow"></div>
    </div>
</template>

<script>
export default {
    props: {
        overlappingContent: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasTitleSlot() {
            return !!this.$slots['header-title'];
        },
        hasStraplineSlot() {
            return !!this.$slots['header-strapline'];
        }
    }
}
</script>

<style scoped>
.header {
    position: relative;
    width: 100%;
    background-color: var(--dark-default);
}
.header::before {
    content: "";
    width: 150px;
    height: 150px;
    background-image: url("../../assets/mission-image-dots.svg");
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: rotate(90deg);
    z-index: 0;
}
.header::after {
    content: "";
    width: 150px;
    height: 150px;
    background-image: url("../../assets/mission-image-dots.svg");
    position: absolute;
    bottom: 50%;
    right: 0px;
    z-index: 0;
}
.header .header-glow {
    position: absolute;
    bottom: -700px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 1000px;
    box-shadow: 0 0 200px 215px var(--brand-primary-blue);
    background-color: var(--brand-primary-blue);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    opacity: 0.1;
    z-index: 0;
}
.header .container .header-content {
    text-align: center;
    color: #fff;
    max-width: 670px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 100px;
    position: relative;
    z-index: 1;
}
.header .container .header-content .header-title {
    position: relative;
    font-size: 40px;
    font-weight: 300;
    text-shadow: 0 2px 15px hsla(0,0%,100%,.5),1px 1px 10px var(--dark-default);
    margin-bottom: 40px;
}
.header .container .header-content .header-title::after {
    content: "";
    height: 1px;
    background: linear-gradient(to right, #0d2c3800 0%, #ffffff8f 50%, #0d2c3800 100%);
    width: 100%;
    max-width: 375px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.header .container .header-content .header-strapline {
    line-height: 2rem;
    margin-bottom: 0;
}
.header .container .header-content .header-strapline.overlapping {
    margin-bottom: 60px !important;
}
@media only screen and (max-width: 400px) {
    .header .header-content .header-title {
        font-size: 32px !important;
    }
    .header .header-content .header-strapline {
        font-size: 16px !important;
    }
}
/* Media Queries */
@media only screen and (max-width: 600px) {
    .header .container .header-content {
        padding-top: 60px;
    }
    .header .container .header-content .header-strapline.overlapping {
        margin-bottom: 0 !important;
    }
}
@media only screen and (max-width: 768px) {
    .header::before,
    .header::after {
        content: none;
    }
}
@media only screen and (min-width: 992px) {
    .header .container .header-content {
        padding-top: 100px;
    }
}
</style>